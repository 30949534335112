<template>
  <div class="row">
    <div class="col-md-8">

      <div class="chat-app-window">
        <!-- Chat Navbar -->
        <div class="active-chat">
          <div class="chat-navbar">
            <header class="chat-header">
              <!-- Avatar & Name -->
              <div class="d-flex align-items-center">

                <b-avatar size="36" :src="detail.user.photo_url" class="mr-1 cursor-pointer badge-minimal" />
                <h6 class="mb-0">
                  {{ detail.user.name }}
                </h6>
              </div>

            </header>
          </div>
          <!-- Chat Log -->
          <vue-perfect-scrollbar class="user-chats scroll-area mb-2 card" ref="refChatLogPS">
            <div class="chats">
              <div v-for="(msgGrp, index) in formattedChatData.filteredDataMessages"
                :key="msgGrp.senderId + String(index)" class="chat"
                :class="{ 'chat-left': msgGrp.senderId == formattedChatData.contact.id }">
                <div class="chat-avatar">
                  <b-avatar size="36" class="avatar-border-2 box-shadow-1" variant="transparent"
                    :src="msgGrp.senderId === formattedChatData.contact.id ? formattedChatData.contact.avatar : profileUserAvatar" />
                </div>
                <div class="chat-body">
                  <div v-for="msgData in msgGrp.messages" :key="msgData.time" class="chat-content">
                    <p v-html="msgData.msg"></p>
                    <div v-if="msgData.attachments_url" style="margin-top: 2px; margin-bottom: 2px;">
                      <div class="d-flex flex-wrap" style="gap: 5px">
                        <div v-for="imageUrl, indexImage in msgData.attachments_url" :key="indexImage"
                          @click="handleOpenImage(imageUrl)" type="button">
                          <b-img v-bind="imgProps" rounded fluid :src="imageUrl" />
                        </div>
                      </div>
                      <!-- <a :href="msgData.attachments_url" target="_blank" style="text-decoration: underline;"
                            :class="{ 'text-white': msgGrp.senderId == formattedChatData.contact.id }">
                            Lihat Lampiran
                          </a> -->
                    </div>
                    <small>{{ msgData.time }}</small>
                  </div>
                </div>
              </div>
            </div>
          </vue-perfect-scrollbar>
        </div>
      </div>

      <!-- <b-card v-for="reply in detail.replies" :key="reply.id" class="border-8">
        <div class="row">
          <div class="col-1">
            <img :src="reply.posted_by.photo_url" style="max-width: 100%;">
          </div>
          <div class="col-11">
            <h1>{{ reply.posted_by.name }}</h1>
            <small>{{ reply.created_at }}</small>
            <hr>
            <p>
              {{ reply.message }}
            </p>
            <div v-if="reply.attachment_url != null">
              <hr>
              <small><a :href="reply.attachment_url" target="_blank">
                  Lihat Lampiran
                </a></small>
            </div>
          </div>
        </div>
      </b-card> -->

      <!-- Reply Ticket -->
      <b-card v-if="detail.status == 'open'" class="border-8">
        <div class="form-group">
          <label for="">Pesan:</label>
          <textarea v-model="formPayload.message" type="text" class="form-control" rows="5"
            placeholder="Jelaskan detail balasan Anda disini..." />
        </div>

        <div class="form-group">
          <label for="">Attachment (Opsional):</label>
          <DropzoneFile @files="getFiles" :clearAttachment="clearAttachment" />
          <!-- <input id="file" ref="file" type="file" @change="changeAttachment()"> -->
          <div class="alert alert-info p-1 mt-1">
            <strong>Catatan</strong>
            <ul>
              <li>File yang didukung adalah: pdf,doc,docx,xls,xlsx,jpg,jpeg,png</li>
              <li>Maksimal ukuran file adalah 2MB</li>
            </ul>
          </div>
        </div>

        <button class="btn btn-success float-right" :disabled="isLoading" @click="replyTicket()">
          <b-spinner label="Loading..." small v-if="isLoading" /> Balas Tiket
        </button>
      </b-card>

    </div>
    <div class="col-md-4">
      <b-card class="border-8">
        <table v-if="detail.uuid != undefined" class="table ml-0 pl-0">
          <tr>
            <td class="ml-0 pl-0" width="15%">
              Subjek
            </td>
            <td width="65%">
              {{ detail.subject }}
            </td>
          </tr>
          <tr>
            <td class="ml-0 pl-0">
              Prioritas
            </td>

            <td style="text-transform: capitalize;">
              <b-badge :variant="priorityScheme[detail.priority]" class="text-capitalize">{{ detail.priority
                }}</b-badge>
            </td>
          </tr>
          <tr>
            <td class="ml-0 pl-0">
              Toko
            </td>

            <td style="text-transform: capitalize;">
              {{ detail.merchant.name }}
            </td>
          </tr>
          <tr>
            <td class="ml-0 pl-0">
              User
            </td>

            <td style="text-transform: capitalize;">
              {{ detail.user.name }} - {{ detail.user.phone }}
            </td>
          </tr>
          <tr>
            <td class="ml-0 pl-0">
              Status
            </td>

            <td>
              <b-badge :variant="statusVariant(detail.status)">
                {{ detail.status.charAt(0).toUpperCase() + detail.status.slice(1) }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <td class="ml-0 pl-0">
              Dibuat
            </td>

            <td>{{ detail.created_at }}</td>
          </tr>
          <tr>
            <td class="ml-0 pl-0">
              Terakhir Dibalas
            </td>

            <td>{{ detail.last_replied_at }}</td>
          </tr>
        </table>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard, BSpinner, BAvatar, BBadge, BImg } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import moment from 'moment'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import DropzoneFile from '@/components/Dropzone/DropzoneFile.vue'

export default {
  title() {
    return `Detail Ticket`
  },
  components: {
    BCard,
    BSpinner,
    BAvatar,
    BBadge,
    DropzoneFile,
    BImg,
    VuePerfectScrollbar
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    const { uuid, photo_url } = JSON.parse(localStorage.getItem('userData'))

    return {
      userUuid: uuid,
      profileUserAvatar: photo_url,
      clearAttachment: false,
      detail: Object,
      formPayload: {
        message: 'Salam Hangat, Tim Toqoo Support',
        attachment: null,
      },
      isLoading: false,
      interval: null,
      imgProps: {
        width: 50,
        height: 50,
      },
      editorOption: {
        theme: 'bubble',
      },
      priorityScheme: {
        low: 'success',
        normal: 'warning',
        high: 'danger',
      }
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        open: 'light-success',
        close: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    formattedChatData() {
      const formattedChatLog = []

      // Contact Sender Ticket
      const contact = {
        id: this.detail?.user?.uuid,
        avatar: this.detail?.user?.photo_url
      }

      let chatLog = []
      let chatMessageSenderId = this.detail?.user?.uuid

      if (this.detail.replies) {
        chatLog = this.detail.replies
      }

      let msgGroup = {
        senderId: chatMessageSenderId,
        messages: [
          {
            attachments_url: this.detail.attachments_url,
            msg: this.detail.message,
            time: this.detail.created_at,
          }
        ],
      }

      if (chatLog.length) {
        chatLog.forEach((msg, index) => {
          if (chatMessageSenderId === msg.posted_by.uuid) {
            if (msg.message) {
              msgGroup.messages.push({
                msg: msg.message,
                attachments_url: msg.attachments_url,
                time: msg.created_at,
              })
            }
          } else {
            chatMessageSenderId = msg?.posted_by?.uuid
            formattedChatLog.push(msgGroup)
            msgGroup = {
              senderId: msg?.posted_by?.uuid,
              messages: [{
                attachments_url: msg.attachments_url,
                msg: msg.message,
                time: msg.created_at,
              }],
            }
          }
          if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
        })
      } else {
        formattedChatLog.push(msgGroup)
      }

      const filteredDataMessages = formattedChatLog.filter(item => item.messages && item.messages.length > 0);

      return { contact, filteredDataMessages }
    },
  },
  mounted() {
    this.getDetail(this.$route.params.id)
    setTimeout(() => {
      this.$nextTick(() => {
        this.scrollToBottomInChatLog()
      })
    }, 5300);
    // this.interval = setInterval(() => {
    //   this.getDetail(this.$route.params.id)
    // }, 5000);
  },
  methods: {
    scrollToBottomInChatLog() {
      const scrollEl = this.$refs.refChatLogPS.$el
      scrollEl.scrollTop = scrollEl.scrollHeight
    },
    handleOpenImage(imageUrl) {
      window.open(imageUrl, '_blank')
    },
    getFiles(files) {
      this.formPayload.attachment = files
    },
    replyTicket() {
      const vm = this
      vm.isLoading = true

      const formData = vm.preparePayload()

      vm.$http.post(`/super-admin/ticket/${vm.$route.params.id}/reply`, formData)
        .then(() => {
          vm.getDetail(vm.$route.params.id)
          vm.formPayload.message = ''
          vm.clearAttachment = true
          vm.isLoading = false
          vm.successNotification(vm, 'Success', 'Balasan berhasil dikirim')
          this.$nextTick(() => {
            this.scrollToBottomInChatLog()
          })
        })
        .catch(() => {
          vm.isLoading = false
          vm.errorNotification(vm, 'Oops!', 'Gagal mengirim balasan')
        })
      vm.clearAttachment = false
    },
    changeAttachment() {
      // eslint-disable-next-line prefer-destructuring
      this.formPayload.attachment = this.$refs.file.files[0]
    },
    getDetail(id) {
      this.$http.get(`/super-admin/ticket/${id}`)
        .then(response => {
          this.detail = response.data.data
        })
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
        if (Array.isArray(this.formPayload[key]) && this.formPayload.attachment.length) {
          this.formPayload[key].forEach((attachment) => {
            form.append("attachments[]", attachment);
          });
        }
      }
      return form
    },
  },
  beforeDestroy() {
    clearInterval(this.interval); // Clear interval when component is destroyed
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";

.chat-app-window .user-chats.scroll-area {
  height: calc(100svh - 25rem);
  // overflow-y: auto;
}
</style>
